<template>
  <div class="myOrder">
    <el-row>
      <el-col :span="4">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="今日订单" name="first"></el-tab-pane>
          <el-tab-pane label="历史订单" name="second"></el-tab-pane>
        </el-tabs>
        <el-col
          :span="24"
          v-for="(item,index) in content"
          :key="index"
          class="orderItem"
          :class="{'channgeColor':item.id == currentOrderId}"
        >
          <el-col :span="24">
            <div @click="choose(index,item)">
              <div>
                <p class="name">{{ item.name }}</p>
                <p class="createTime">{{ item.createTimeFormat }}</p>
                <p class="status">
                  <i class="leftAngle"></i>
                  <span class="statusText">{{ item.status }}</span>
                </p>
              </div>
            </div>
          </el-col>
        </el-col>
        <el-col :span="22" v-if="content.length === 0">
          <p class="noOrder">暂无订单</p>
        </el-col>
      </el-col>
      <el-col :span="20">
        <el-steps :active="active" v-if="false">
          <el-step title="提交意向 2017/12/05 10:19:09"></el-step>
          <el-step title="卖家确定订单  已等待15分16秒"></el-step>
          <el-step title="交易完成"></el-step>
        </el-steps>
        <div class="green" v-if="false">
          <el-col :span="7">卖家已确认！由于行情波动频繁，请及时与卖家联系</el-col>
          <el-col :span="7" :offset="10">确定时间：2017/12/22 09：50</el-col>
        </div>
        <div class="gray" v-if="false">
          <el-col :span="2">卖家已取消</el-col>
          <el-col :span="7" :offset="15">取消时间：2017/12/22 09：50</el-col>
        </div>
        <el-row class="orderContainer">
          <el-col :span="24" class="orderDetails">
            <el-col :span="7">
              <p class="orderNumber">订单号： {{ detail.id }}</p>
            </el-col>
            <el-col :span="17"></el-col>
          </el-col>
          <el-col :span="24" class="productDetails">
            <el-col :span="7">商品明细</el-col>
          </el-col>
          <el-col :span="24">
            <div class="productDetailsTitle">
              <div class="titleItem">
                <p>品名</p>
              </div>
              <div class="titleItem">
                <p>单价</p>
              </div>
              <div class="titleItem">
                <p>计量单位</p>
              </div>
              <div class="titleItem">
                <p>产地</p>
              </div>
              <div class="titleItem">
                <p>仓库</p>
              </div>
            </div>
            <div class="productDetailsContainer">
              <div class="listItem">
                <p>{{detail.name}}</p>
              </div>
              <div class="listItem">
                <p>{{detail.price}}</p>
              </div>
              <div class="listItem">
                <p>{{detail.meterageUnit}}</p>
              </div>
              <div class="listItem">
                <p>{{detail.origin}}</p>
              </div>
              <div class="listItem">
                <p>{{detail.warehouse}}</p>
              </div>
            </div>
          </el-col>
          <el-col :span="24" class="buyDetails">
            <el-col :span="7">
              买家信息
              <span class="chooseBuyer">（请选择买家）</span>
            </el-col>
          </el-col>
          <el-col :span="24" class="buyDetailsContainer">
            <div
              v-for="item in buyList"
              @click="chooseBuy(item)"
              class="buyItem"
              :key="item.offid"
              :class="chooseBuyId == item.offid ? 'activeBuyItem' : ''"
            >
              <p>{{ item.name }}</p>
              <p>{{ item.price }}</p>
              <p>{{ item.meterageUnit }}</p>
              <p>{{ item.firmName }}</p>
              <p>{{ item.cellphone }}</p>
            </div>
          </el-col>
          <el-col :span="24" v-if="buyList.length !== 0" class="buyPagination">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="current"
              :page-size="100"
              layout="total, prev, pager, next"
              :total="totalPage"
            ></el-pagination>
          </el-col>
          <el-col :span="24" class="sellDetails">
            <el-col :span="7">卖家信息</el-col>
          </el-col>
          <el-col :span="24">
            <div class="sellDetailsContainer">
              <el-col :span="8">公司名称：{{detail.enterpriseName}}</el-col>
              <el-col :span="8">联系人：{{detail.firmName}}</el-col>
              <el-col :span="8">联系方式：{{detail.cellphone}}</el-col>
              <el-col :span="8">开户银行：{{detail.bankName}}</el-col>
              <el-col :span="8">开户名：{{detail.bankFirmname}}</el-col>
              <el-col :span="8">卡号：{{detail.bankNumber}}</el-col>
            </div>
          </el-col>
          <el-col :span="24" class="confirmTrade">
            <el-button type="danger" :disabled="!currentOrderId" @click="param_confirmOrder()">交易确认</el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 1,
      activeName: "first",
      today: [],
      yesterday: [],
      content: [],
      detail: {},
      buyList: [],
      chooseOffid: null,
      chooseCommodityId: null,
      chooseBuyId: null,
      current: 1,
      totalPage: null,
      currentOrderId: null
    };
  },
  mounted() {
    this.param_querySellerOrderOverview();
  },
  methods: {
    handleCurrentChange(val) {
      this.current = val;
      this.param_queryQuotationSheetDetails(this.currentOrderId);
    },
    param_confirmOrder() {
      if (!this.chooseCommodityId) {
        this.$EL_MESSAGE("请选择买家");
        return false;
      }
      protocolFwd.param_confirmOrder.param.offerId = this.chooseOffid;
      protocolFwd.param_confirmOrder.param.commodityId = this.chooseCommodityId;
      http.postFront(protocolFwd.param_confirmOrder).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.$EL_MESSAGE(message);
          this.$router.push("/sellerCenter/dealOrder");
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },

    param_queryQuotationSheetDetails(index) {
      protocolFwd.param_queryQuotationSheetDetails.param.offId = null;
      protocolFwd.param_queryQuotationSheetDetails.param.orderId = index;
      protocolFwd.param_queryQuotationSheetDetails.param.page =
        this.current - 1;
      protocolFwd.param_queryQuotationSheetDetails.param.size = 10;
      protocolFwd.param_queryQuotationSheetDetails.param.sort = [
        { direction: "DESC", property: "id" }
      ];
      http
        .postFront(protocolFwd.param_queryQuotationSheetDetails)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.buyList = value.content;
            this.totalPage = value.totalElements;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    choose(index, item) {
      this.currentOrderId = item.id;
      this.param_queryMyOrderDetails(item.id);
      this.param_queryQuotationSheetDetails(item.id);
      protocolFwd.param_confirmOrder.param.offerId = item.id;
    },
    param_queryMyOrderDetails(index) {
      protocolFwd.param_queryMyOrderDetails.param.orderId = index;
      http.postFront(protocolFwd.param_queryMyOrderDetails).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.detail = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    param_querySellerOrderOverview() {
      http
        .postFront(protocolFwd.param_querySellerOrderOverview)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.today = value.today;
            this.yesterday = value.history;
            this.content = value.today;
            for (let i = 0; i < this.content.length; i++) {
              this.content[i].createTimeFormat = global.util.DateFormate(
                this.content[i].createTime
              );
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    handleClick() {
      if (this.activeName == "first") {
        for (let i = 0; i < this.content.length; i++) {
          this.content[i].createTimeFormat = global.util.DateFormate(
            this.content[i].createTime
          );
        }
        this.content = this.today;
      } else {
        for (let i = 0; i < this.content.length; i++) {
          this.content[i].createTimeFormat = global.util.DateFormate(
            this.content[i].createTime
          );
        }
        this.content = this.yesterday;
      }
    },
    chooseBuy(item) {
      this.chooseCommodityId = item.commodityId;
      this.chooseOffid = item.offid;
      this.chooseBuyId = item.offid;
    }
  }
};
</script>
<style lang="scss" scoped>
.myOrder {
  padding: 0 20px 0 0;
}
.myOrder .orderItem {
  position: relative;
  padding: 5px;
  margin-bottom: 10px;
  line-height: 22px;
  cursor: pointer;
  border: 1px solid #dcdcdc;
}
.myOrder .orderItem .createTime {
  color: #666666;
}
.myOrder .orderItem .status {
  position: absolute;
  top: 10px;
  right: 0;
  line-height: 20px;
}
.myOrder .orderItem .status .leftAngle {
  float: left;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #f4a83c transparent transparent;
}
.myOrder .orderItem .status .statusText {
  float: left;
  display: block;
  padding: 0 8px 0 5px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  background-color: #f4a83c;
}
.myOrder .orderItem.channgeColor {
  border: 1px solid orange;
}
.myOrder .orderItem .status {
  position: absolute;
  top: 5px;
  right: 0;
}
.myOrder .orderDetails {
  padding: 0 10px;
  line-height: 35px;
  background-color: #f2f2f2;
}
.myOrder .orderDetails .orderDate {
  color: #666666;
  text-align: right;
}
.myOrder .orderContainer {
  padding-left: 20px;
}
.myOrder .productDetails {
  margin-top: 2px;
  padding: 0 10px;
  line-height: 35px;
  font-weight: 700;
  background-color: #f2f2f2;
}
.productDetailsTitle {
  line-height: 35px;
  overflow: hidden;
}
.productDetailsTitle .titleItem {
  float: left;
  width: 20%;
  text-align: center;
}
.productDetailsContainer {
  line-height: 35px;
  overflow: hidden;
}
.productDetailsContainer .listItem {
  float: left;
  width: 20%;
  min-height: 35px;
  color: #666666;
  text-align: center;
}
.myOrder .buyDetails {
  padding: 0 10px;
  line-height: 35px;
  font-weight: 700;
  background-color: #f2f2f2;
}
.myOrder .buyDetails .chooseBuyer {
  font-size: 12px;
  color: $commonThemeColor;
}
.myOrder .buyDetailsContainer .buyItem {
  line-height: 35px;
  overflow: hidden;
  text-align: center;
}
.myOrder .buyDetailsContainer .buyItem p {
  float: left;
  width: 20%;
  min-height: 35px;
}
.myOrder .sellDetails {
  margin-top: 2px;
  padding: 0 10px;
  line-height: 35px;
  font-weight: 700;
  background-color: #f2f2f2;
}
.myOrder .sellDetailsContainer {
  padding: 0 10px;
  line-height: 35px;
}
.myOrder .confirmTrade {
  margin-top: 20px;
  text-align: center;
}
.myOrder .noOrder {
  margin: 10px 0;
  text-align: center;
  color: #666666;
}
.myOrder .buyPagination {
  text-align: right;
}
.green {
  background: rgba(98, 158, 77, 1);
  width: 100%;
  height: 20px;
  text-align: center;
  color: white;
}
.gray {
  background: rgba(215, 215, 215, 1);
  width: 100%;
  height: 20px;
  text-align: center;
  color: white;
}
.channgeColor {
  border: 1px solid orange;
}
.buyItem {
  cursor: pointer;
  overflow: hidden;
}
.activeBuyItem {
  border: 1px solid orange;
}
</style>
